import React, { useState } from "react";
import Banner from "../common-layouts/Banner";
import { Clock, MapPin, Phone } from "lucide-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const SuccessNotify = () => toast.success("Message sent successfully !");

  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    phone: "",
    commit: "",
  });

  const [loading, setloading] = useState(false);

  function changehandler(e) {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  }

  async function submitbtn(e) {
    e.preventDefault();
    setloading(true);

    // Validate email and phone number
    const isValidEmail = validateEmail(formdata.email);
    const isValidPhoneNumber = validatePhoneNumber(formdata.phone);

    if (!isValidEmail) {
      toast.error("Please enter a valid email address!");
      setloading(false);
      return;
    }

    if (!isValidPhoneNumber) {
      toast.error("Please enter a valid phone number!");
      setloading(false);
      return;
    }

    try {
      await axios.post(
        "https://lms.waveanalyticallabs.com/api/send/mail",
        formdata
      );

      setloading(false);
      SuccessNotify();

      setformdata({
        name: "",
        email: "",
        phone: "",
        commit: "",
      });
    } catch (error) {
      setloading(false);
      toast.error("Failed to Submit the data");
    }
  }

  // Email validation function
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Phone number validation function
  const validatePhoneNumber = (phoneNumber) => {
    const re = /^\d{10}$/;
    return re.test(phoneNumber);
  };

  return (
    <div id="__next">
      <Banner title="Contact Us" />
      <section className="contact-area pt-130 pb-115">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-5 col-12  fadeInLeft"
              data-aos="fade-right"
            >
              <div
                className="tpcontact mr-60 mb-60  fadeInUp"
                data--delay=".2s"
              >
                <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-20">
                    <MapPin size={50} />
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Address line</h4>
                    <span>
                      <a href="https://goo.gl/maps/bDnk31fs1s1eW5TRA">
                        WAVE ANALYTICAL LABS LLP PLOT NO 10, AMRAKUNJ IND. PARK,
                        NARODA GIDC PHASE 3, AHMEDABAD, GUJARAT, INDIA, 382330
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="tpcontact mr-60 mb-60  fadeInUp">
                <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-20">
                    <Phone size={50} />
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Phone Number</h4>
                    <span>
                      <a href="tel:+91 8140825509">(+91) 8140825509</a>
                    </span>
                    <span>
                      <a href="tel:+91 7949243014">(+91) 7949243014</a>
                      </span>
                  </div>
                </div>
              </div>
              <div className="tpcontact mr-60 mb-60  " data--delay=".6s">
                <div className="tpcontact__item text-center">
                  <div className="tpcontact__icon mb-20">
                    <Clock size={50} />
                  </div>
                  <div className="tpcontact__address">
                    <h4 className="tpcontact__title mb-15">Opening Hours</h4>
                    <span>
                      Monday - Saturday <br />
                      09:00 AM - 07:00 PM
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 col-12" data-aos="fade-left">
              <div className="contactform  fadeInRight">
                <h4 className="contactform__title mb-35">
                  Send us a Message :
                </h4>
                <div className="contactform__list mb-60">
                  {/* start form */}
                  <form id="contact-form" onSubmit={submitbtn}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="contactform__input mb-30">
                          <input
                            name="name"
                            type="text"
                            value={formdata.name || ""}
                            onChange={changehandler}
                            placeholder="Enter your Name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <input
                            name="email"
                            type="email"
                            value={formdata.email || ""}
                            onChange={changehandler}
                            placeholder="Enter your mail"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="contactform__input mb-30">
                          <input
                            name="phone"
                            type="number"
                            value={formdata.phone || ""}
                            onChange={changehandler}
                            placeholder="Enter your number"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contactform__input mb-30">
                          <textarea
                            name="commit"
                            value={formdata.commit || ""}
                            onChange={changehandler}
                            placeholder="Type your comment"
                            defaultValue={""}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="contactform__input mb-30-btn">
                          <button
                            type="submit"
                            className="tp-btn"
                            disabled={loading}
                          >
                            {loading ? (
                              <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            ) : (
                              "send Message"
                            )}
                          </button>
                        </div>
                        <ToastContainer />
                      </div>
                    </div>
                  </form>
                  {/* end form */}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tpcontactmap">
                      <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.956117164464!2d72.6708737!3d23.098702199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e81bdf4694f35%3A0xbe75e18c373ed98b!2sWave%20Analytical%20Labs%20LLP!5e0!3m2!1sen!2sin!4v1713868229481!5m2!1sen!2sin" 
                        width={600}
                        height={450}
                        allowfullscreen=""
                        loading="lazy"
                        title="maps"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58731.00412966693!2d72.57257409984669!3d23.071906237150255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e819b21b28943%3A0x3f48d18a54b93ebc!2sWave%20Analytical%20%26%20Research%20Center!5e0!3m2!1sen!2sin!4v1689684867638!5m2!1sen!2sin"
          className="india-map"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section> */}
    </div>
  );
}

export default Contact;
