import React from "react";
import { Facebook, Instagram, Twitter} from "lucide-react";
import logo from "../assets/newLogo.jpeg";
import { Link } from "react-router-dom";
function Footer() {
  const currentYear = new Date().getFullYear();
  function Top() {
    window.scrollTo(0, 0);
  }
  return (
    <footer>
      <div className="footer-area  pt-50 pb-50">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget footer-col-1 mb-50  fadeInUp"
                data--delay=".2s"
              >
                <h4 className="footer-widget__title mb-30">
                  <a href="index">
                    <img src={logo} alt="logo" />
                  </a>
                </h4>
                <div className="footer-widget__social">
                  <a
                    href="https://twitter.com/waveanalytical"
                    className="tp-f-twitter"
                    target="_blank"
                    rel="noreferrer" 
                  >
                    <div>
                      <Twitter />
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/waveanalytical/"
                    className="tp-f-fb"
                    target="_blank"
                    rel="noreferrer" 
                  >
                    <div>
                      <Facebook />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/waveanalytical/"
                    className="tp-f-youtube"
                    target="_blank"
                    rel="noreferrer" 
                  >
                    <div>
                      <Instagram />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget footer-col-2 mb-50  fadeInUp"
                data--delay=".4s"
              >
                <h4 className="footer-widget__title mb-20">Why Choose Us</h4>
                <div className="footer-widget__links">
                  <ul>
                    <li>
                      <Link to="/" onClick={Top}>
                        W - We Serve Quality
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={Top}>
                        A - Analytical Support 24*7
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={Top}>
                        V - Valuable Dealing Offered
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={Top}>
                        E- Ethical Working Process
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget footer-col-2 mb-50  fadeInUp"
                data--delay=".4s"
              >
                <h4 className="footer-widget__title mb-20">Useful links</h4>
                <div className="footer-widget__links">
                  <ul>
                    <li>
                      <Link to="/contact" onClick={Top}>
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={Top}>
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" onClick={Top}>
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/training" onClick={Top}>
                        Training
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div
                className="footer-widget footer-col-3  fadeInUp"
                data--delay=".4s"
              >
                <h4 className="footer-widget__title mb-20">Contact info</h4>
                <div className="footer-widget__links">
                  <ul>
                    <li>
                      <a >
                        WAVE ANALYTICAL LABS LLP PLOT NO 10, AMRAKUNJ IND. PARK,
                        NARODA GIDC PHASE 3, AHMEDABAD, GUJARAT, INDIA, 382330
                      </a>
                    </li>
                    <li>
                      <a href="tel:+91 8140825509">(+91) 8140825509</a>
                    </li>
                    <li>
                      <a href="tel:+91 7949243014">(+91) 7949243014</a>
                    </li>
                    <li>
                      <a href="mailto:waveanalyticallabs@gmail.com">
                      waveanalyticallabs@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@waveanalytical.com">
                        info@waveanalyticallabs.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-area-bottom ">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="footer-widget__copyright last-footer-details">
                  <span>
                  Copyrights © {currentYear} All Rights Reserved 
                  </span>
                  <span>
                   <a href="https://uptakeinfotech.com/" target="_blank"> Powered By : Uptake Infotech Pvt. Ltd.</a>
                  </span>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-12">
              {/* <div className="footer-widget__copyright-info info-direction">
                <ul className="d-flex align-items-center">
                  <li>
                    <a href="#">Terms and conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
