import { useState, useEffect } from "react";
import Banner from "../common-layouts/Banner";
import { data } from "../Data/data";
import { X, Eye } from "lucide-react";
import "./service.css";
function Service() {
  const [modal, setmodal] = useState(false);

  const [selectedData, setselectedData] = useState(null);
  const [selectedContent, setselectedContent] = useState(null);
  useEffect(() => {
    if (modal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [modal]);

  function btnService(title, content) {
    setselectedData(title);
    setselectedContent(content);
    setmodal(true);
  }

  return (
    <>
      <Banner title="services" />

      <section className="services-area pt-70 pb-50 grey-bg">
        <div className="container" data-aos="fade-up">
          <div className="row text-center">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="tp-section">
                <span className="tp-section__sub-title left-line right-line mb-50">
                  our Services
                </span>
              </div>
            </div>
          </div>
          <div class="content-wrapper">
            {data.map((item, index) => (
              <div
                class="news-card"
                key={index}
                onClick={() => btnService(item.title, item.content)}
              >
                <img src={item.img} alt="" class="news-card__image" />
                <div class="news-card__text-wrapper">
                  <h4 class="news-card__title">{item.title}</h4>
                  <div class="news-card__details-wrapper">
                    <button
                      class="news-card__read-more"
                      onClick={() => btnService(item.title, item.content)}
                    >
                      <Eye />
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <div className="services-item container mx-2 p-3 mt-20">
              <h6 className="card_text fw-bold text-center mb-0">
                To Enrich the Merit For Pharmaceutical , Food , Cosmetics and
                Other Industries by Being a Most Trusted Organization For
                Analytical Support ans Services to Convey Public Health
              </h6>
            </div>
          </div>
        </div>
        {modal && (
          <>
            <div
              className="modal-wrapper"
              onClick={() => setmodal(false)}
            ></div>
            <div className="modal-container" data-aos="fade-up">
              <div className="modal-heading">
                <h6>{selectedData} Services</h6>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <X color="black" onClick={() => setmodal(false)} />
                </button>
              </div>
              <hr />
              <div className="selected-content">
                <span
                  dangerouslySetInnerHTML={{ __html: selectedContent }}
                ></span>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
}

export default Service;
