import React from "react";
import abt1 from "../assets/abt-doctors.jpg";
import abt2 from "../assets/abt-doctor-2.jpg";
import abt3 from "../assets/abt-doctor-3.jpg";
import vision from "../assets/chemical.jpg";
import electric from "../assets/electrical.svg";
import lab from "../assets/management.svg";
import meeting from "../assets/meeting-room.svg";
import microscope from "../assets/microscope.svg";
import secure from "../assets/secure.svg";
import camera from "../assets/security-camera.svg";
import warehouse from "../assets/warehouse.svg";
import bin from "../assets/recycle-bin.svg";
import fire from "../assets/fire-extinguisher.svg";
import Banner from "../common-layouts/Banner";
function About() {
  return (
    <>
      <div id="__next">
        <Banner title="About Us" />
        <section className="about-area pt-130 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-4 col-12">
                <div
                  className="tp-about-thumb mb-60  fadeInLeft"
                  data--delay=".3s"
                >
                  <div className="tp-ab-img d-flex">
                    <div className="tp-ab-main-img p-relative">
                      <img data-aos="fade-right" src={abt1} alt="about-thumb" />
                      <div
                        className="about__exprience tp-ab-counter"
                        data-aos="fade-up"
                      >
                        <h3 className="counter">
                          <div className="d-flex align-items-center justify-content-center">
                            <span>07</span>
                          </div>
                        </h3>
                        <i>
                          Years of <br />
                          Experience
                        </i>
                       
                      </div>
                      
                    </div>
                    <div className="tp-ab-shape d-none d-md-block d-lg-none d-xl-block">
                      <img
                        className="ab-shape-one"
                        src={abt2}
                        alt="about-shape"
                        data-aos="fade-left"
                      />
                      <img
                        className="ab-shape-two"
                        src={abt3}
                        alt="abt3 shape"
                        data-aos="fade-left"
                      />
                    </div>
                  </div>
                  <p className="mt-4">
                  Wave Analytical Labs LLP has been directed and operated by
                      the same highly qualified team of experienced
                      professionals and academicians who have established the
                      benchmark of best service provider in pharmaceutical
                      industry in the name of Wave Analytical & Research Center.
                      Moreover, we are associated with several educational &
                      research institutes where we provide guidance and
                      technical support.
                    </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-8 col-12">
                <div
                  className="about-content about-align mb-60"
                  data--delay=".3s"
                >
                  <div className="tp-section" data-aos="fade-left">
                    <h3 className="tp-section__title ab-title mb-25">
                      Excellent Analysis with Assured Analytical Support!
                    </h3>
                    <p className="mr-20 mb-40">
                      Wave Analytical Labs LLP (Formerly known as Wave
                      Analytical & Research Center) is situated at Ahmedabad,
                      India that provides a comprehensive range of Analytical
                      Support & Analytical testing Services to Pharmaceutical,
                      Food, Cosmetics, Herbal, Beverage, Water, Soil, Spices,
                      Dairy Products, Oils, Chemicals, Nutrition etc. Industries
                      across the globe.
                      <br />
                    </p>
                    <p>
                      Wave Analytical Labs LLP is an expansion of facilities
                      with the addition of specialized analytical services of
                      Wave Analytical & Research Center at a new location in
                      Ahmedabad, India. (Wave Analytical & Research Center is
                      accredited with ISO:IEC 17025:2017, Gujarat State FDCA &
                      GLP Certifications and providing a comprehensive range of
                      Analytical Testing & Support Services to Pharmaceutical,
                      Nutraceutical, Food, Cosmetics, Chemical, Water, Beverage
                      Industries etc. across the globe with an advanced
                      facilities and expert team in their respective subject.
                      Specially providing general pharmaceutical testing (IP,
                      BP, USP, EP), Analytical method development and
                      validation, Method verification, Comparative dissolution
                      studies, Stability studies, R & D, F & D activities kind
                      of services with extraordinary quality and highly accurate
                      work. Dedicatedly Providing constant support to more than
                      our 175+ pharma clients since 2018 by analyzing more than
                      65000 (and cont..) pharma samples and 500+ projects of
                      AMV, CDP, PV, Method Development etc.)
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="project-area pt-20 ">
          <div className="container">
            <div className="row flex-column-reverse flex-lg-row">
              <div className="col-lg-6  ">
                <div
                  className="tpviewtext   fadeInRight"
                  data--delay=".2s"
                  data-aos="fade-right"
                >
                  <h5 className="tpproject-title pb-20">Our Vision</h5>
                  <p className="tpviewtext__para">
                    To enrich the merit for pharmaceutical, food, cosmetics and
                    similar industries by being a most trusted organization for
                    Analytical Testings And Analytical Support Services to
                    convoy public health.
                    <br />
                    <br />
                    Wave Analytical Labs LLP follows global standards and
                    working with “Customer Centric Attitude”. We are
                    continuously updating our quality standards, internal
                    processes, team management and technical skills in order to
                    provide the best services to our clients.
                    <br />
                    <br />
                    Wave Analytical Labs LLP is determined and focused about
                    deliver in accurate and precise results which ensuring the
                    health of society
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="tpoverview mb-60  fadeInLeft"
                  data--delay=".2s"
                  data-aos="fade-left"
                >
                  <center>
                    <img src={vision} alt="vision" className="vision" />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="services-area pt-95 pb-90 grey-bg mt-60 fix">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8 col-12">
                <div className="tp-section" data-aos="fade">
                  <span className="tp-section__sub-title left-line mb-20">
                    Location & Infrastructure
                  </span>
                  <h6 className="mb-50">
                    To serve the best, we have developed a well-designed
                    facility at supreme location of the Ahmedabad city and
                    easily reachable by clients and employees.
                  </h6>
                </div>
              </div>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={electric} id="icons" alt="electric" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Equipment management</p>
                    <p>
                      Key analytical equipments are supported by 21 CFR
                      complainant software and UPS.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={fire} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Safety System</p>
                    <p>Fire safety.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={camera} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Surveillance Management</p>
                    <p>CCTV Surveillance</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={microscope} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Instrumental Zone Management</p>
                    <p>
                      Whole Instrumental zone is connected to Variable
                      Refrigerant Volume (VRV).
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={warehouse} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Storage Provision</p>
                    <p>
                      Quality modular furniture, Fume hood for working with
                      chemicals, Separate storage facility for hazardous
                      chemicals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={meeting} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Leisure And Discussion</p>
                    <p>
                      The facility of Digital library, Cafeteria and
                      Well-furnished Conference room
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={secure} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Entry Restriction System</p>
                    <p>Entry access control for inside lab area.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6">
                <div
                  className="counter__item blue-border mb-30  fadeInUp"
                  data--delay=".2s"
                >
                  <div className="counter__icon blue-hard mb-15">
                    <img src={bin} alt="" id="icons" />
                  </div>
                  <div className="counter__content">
                    <p className="loc-title">Waste Management</p>
                    <p>
                      Proper Waste segregation system for plastic waste,
                      chemical hazards etc
                    </p>
                  </div>
                </div>
              </div>
              <center>
                <div className="col-xl-3 col-md-6">
                  <div className="counter__item blue-border mb-30 p-3">
                    <div className="counter__icon blue-hard mb-15 lab-card px-5 p-2 ">
                      <img src={lab} alt="" id="lab-icon" />
                    </div>
                    <div className="counter__content">
                      <p className="loc-title">Laboratory Management</p>
                      <p>
                        Laboratory area residing clean room paneling with epoxy
                        flooring, High efficiency particulate air (HEPA) and Air
                        handling unit(AHU).
                      </p>
                    </div>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default About;
