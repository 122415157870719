import React from "react";

function ProgramCard({ icon, content }) {
  return (
    <div className="services-item all-center-column mb-40">
      <div className="services-item__icon sky-icon mb-30">
        {icon}
      </div>
      <div className="services-item__content train-card">
        <p className="text-center" style={{ fontSize: "17px" }}>{content}</p>
      </div>
    </div>
  );
}

export default ProgramCard;
