import banner from "../assets/wave-main.jpeg";
import { useRef, useState } from "react";
import about_bg_img from "../assets/tester.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CountUp from "react-countup";
import { Link, useNavigate } from "react-router-dom";
import {
  ServerCog,
  Mail,
  Mouse,
  MoveLeft,
  MoveRight,
  HelpingHand,
  TestTube2,
  Soup,
  Leaf,
  Droplet,
  Biohazard,
  Trophy,
  Lightbulb,
  Orbit,
  BarChart,
  Users,
  Microscope,
} from "lucide-react";
import ScrollTrigger from "react-scroll-trigger";

function Home() {
  const [counteron, setcounteron] = useState(false);

  const slide_serviceArea = useRef(null);

  var settings = {
    dots: true,
    infinite: false,
    speed: 2000,
    rows: 2,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();

  function handleNavigate(route) {
    navigate(route);
  }
  return (
    <>
      <div id="__next">
        <div>
          <div className="body-overlay" />
          <main>
            <section className="banner-area p-relative">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8" data-aos="fade-up">
                    <div className="banner__content mt-100 pb-0 pt-105 mb-50">
                      <span className="banner__sub-title mb-20">
                        Welcome To
                      </span>
                      <h2 className="banner__title mb-30">
                        Wave Analytical <br />
                        Labs LLP
                      </h2>

                      <div className="banner__btn">
                        <Link className="tp-btn" to="/contact">
                          Appointment
                        </Link>
                        <Link className="tp-btn-second ml-25" to="/about">
                          About us
                        </Link>
                      </div>
                    </div>
                    <div className="banner__box-item">
                      <div className="row">
                        {/* <div className="col-xl-4 col-lg-4 col-md-6">
                          <div
                            className="banner__item d-flex undefined align-items-center mb-30  fadeInUp"
                            data--delay=".2s"
                          >
                            <div className="banner__item-icon">
                              <Eye />
                            </div>
                            <div className="banner__item-content">
                              <span>View our Vision</span>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <div
                            className="banner__item d-flex pink-border align-items-center mb-30  fadeInUp"
                            data--delay=".2s"
                          >
                            <div className="banner__item-icon pink-icon">
                              <ServerCog />
                            </div>
                            <div
                              className="banner__item-content"
                              onClick={() => handleNavigate("/service")}
                            >
                              <span>Our Services</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                          <div
                            className="banner__item d-flex green-border align-items-center mb-30  fadeInUp"
                            data--delay=".2s"
                          >
                            <div className="banner__item-icon green-icon">
                              <Mail />
                            </div>
                            <div
                              className="banner__item-content"
                              onClick={() => handleNavigate("/contact")}
                            >
                              <span>Inquiry</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bannerscroll d-none d-xl-block"
                data-aos="fade-right"
              >
                <div className="banner-scroll-btn">
                  <a className="bannerscroll-icon" href="#tp-about-scroll">
                    <Mouse className="mouse" />
                    <span>Scrool Down</span>
                  </a>
                </div>
              </div>
              <div className="banner__shape d-none d-lg-block" data-aos="fade">
                <img src={banner} alt="banner-img" />
              </div>
            </section>
            <section className="services-area pt-95 pb-90 grey-bg  fix">
              <div className="container" data-aos="fade-up">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="tp-section">
                      <span className="tp-section__sub-title left-line mb-20">
                        our Services
                      </span>
                      <h3 className="tp-section__title mb-50">Service Area</h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="tp-services d-flex align-items-center">
                      <div className="services-p">
                        <MoveLeft
                          onClick={() => slide_serviceArea.current.slickPrev()}
                        />
                      </div>
                      <div className="services-n">
                        <MoveRight
                          onClick={() => slide_serviceArea.current.slickNext()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="services-slider  fadeInUp" data--delay=".3s">
                  <div>
                    <div className="swiper service-active"></div>
                  </div>

                  {/* slider slick start */}
                  <Slider {...settings} ref={slide_serviceArea}>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <HelpingHand size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title ">
                            <a href={404}>Analytical Support</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <TestTube2 size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title ">
                            <a href={404}>Analytical Testing</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <Soup size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title ">
                            <a href={404}>Food Testing</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <Leaf />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title">
                            <a href={404}>Herbal & Ayurvedic Products</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <Droplet size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title">
                            <a href={404}>Water Analysis</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <Biohazard size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title ">
                            <a href={404}>General Chemical Testing</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <Orbit size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title">
                            <a href={404}>Physico Chemical Testing</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <BarChart size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title">
                            <a href={404}>Stability Testing</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="services-item all-center-column mb-40">
                        <div className="services-item__icon sky-icon mb-30">
                          <Microscope size={40} />
                        </div>
                        <div className="services-item__content">
                          <h4 className="services-item__tp-title">
                            <a href={404}>Microbiological & sterility</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Slider>
                  {/* slider slick end  */}
                </div>
              </div>
            </section>
            <section id="tp-about-scroll" className="about-area pb-70">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-lg-12" data-aos="fade-right">
                    <div
                      className="about__thumb mb-60  fadeInLeft"
                      data--delay=".4s"
                    >
                      <div className="about__img">
                        <img src={about_bg_img} alt="about-bg-img" />
                        <div className="about__exprience">
                          <h3 className="counter">
                            <ScrollTrigger onEnter={() => setcounteron(true)}>
                              <div className="d-flex align-items-center justify-content-center">
                                {counteron && (
                                  <span>
                                    0<CountUp start={0} end={7} delay={0} />
                                  </span>
                                )}
                              </div>
                            </ScrollTrigger>
                          </h3>
                          <i>
                            Years of <br />
                            Experience
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12" data-aos="fade-left">
                    <div
                      className="tp-about__content pt-125 ml-60 mb-50  fadeInRight"
                      data--delay=".4s"
                    >
                      <div className="tp-section">
                        <span className="tp-section__sub-title left-line mb-25">
                          About
                        </span>
                        <h3 className="tp-section__title tp-ab-sm-title mb-45">
                        Excellent Analysis with Assured Analytical Support!
                        </h3>
                        <i>
                          Wave Analytical Labs LLP (Formerly known as Wave
                          Analytical & Research Center) is situated at
                          Ahmedabad, India that provides a comprehensive range
                          of Analytical Support & Analytical testing Services to
                          Pharmaceutical, Food, Cosmetics, Herbal, Beverage,
                          Water, Soil, Spices, Dairy Products, Oils, Chemicals,
                          Nutrition etc. Industries across the globe. Moreover,
                          Wave Analytical & Research Center is associated with
                          several educational institutes where we provide
                          guidance and technical facilities to research scholars
                          and students.
                        </i>
                       
                      </div>
                      <div className="tp-about__btn">
                        <a className="tp-btn" href="about">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="choose-area theme-bg ">
              <div className="card-overlay pt-40">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tp-section text-center">
                      <h3 className="tp-section__title title-white mb-85">
                        Why Choose Us
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-6">
                    <div
                      className="tp-choose__item ml-75 mb-100  fadeInUp"
                      data--delay=".8s"
                    >
                      <div className="tp-choose__icon mb-40">
                        <h1>w</h1>
                      </div>
                      <div className="tp-choose__content">
                        <h4 className="tp-choose__title mb-20">
                          We Serve <br />
                          Quality
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <div
                      className="tp-choose__item ml-75 mb-100  fadeInUp"
                      data--delay=".8s"
                    >
                      <div className="tp-choose__icon pink-icon mb-40">
                        <h1>a</h1>
                      </div>
                      <div className="tp-choose__content">
                        <h4 className="tp-choose__title mb-20">
                          Analytical Support <br />
                          24 * 7
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <div
                      className="tp-choose__item ml-75 mb-100  fadeInUp"
                      data--delay=".8s"
                    >
                      <div className="tp-choose__icon  mb-40">
                        <h1>v</h1>
                      </div>
                      <div className="tp-choose__content">
                        <h4 className="tp-choose__title mb-20">
                          Valuable Dealing <br />
                          Offered
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-6">
                    <div
                      className="tp-choose__item ml-75 mb-100  fadeInUp"
                      data--delay=".8s"
                    >
                      <div className="tp-choose__icon pink-icon mb-40">
                        <h1>e</h1>
                      </div>
                      <div className="tp-choose__content">
                        <h4 className="tp-choose__title mb-20">
                          Ethical Working <br />
                          Process
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </section> */}

            <section className="team-area grey-bg pt-120 pb-80">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-md-8 col-12">
                    <div className="tp-section" data-aos="fade-up">
                      <h3 className="tp-section__title mb-75">Core Values</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-container team-active  fadeInUp"
                  data--delay=".3s"
                >
                  {/* responsive cards */}
                  <ul className="cards">
                    <li className="cards_item ">
                      <div className="my-card justify-content-center align-items-center">
                        <div className="card_image p-4">
                          <Trophy size={100} color="#ef8405" />
                        </div>
                        <div className="card_content">
                          <h2 className="card_title text-center">
                            Determination & Accomplishment
                          </h2>
                          <p className="card_text mt-20 text-center">
                            We are working decisively with a strong desire to
                            accomplish the target in time limits
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="cards_item">
                      <div className="my-card justify-content-center align-items-center">
                        <div className="card_image p-4">
                          <Lightbulb size={100} color="#ef8405" />
                        </div>
                        <div className="card_content">
                          <h2 className="card_title text-center">Knowledge & Innovation</h2>
                          <p className="card_text mt-20 text-center">
                            We dedicatedly working on improvement of our
                            knowledge and technical practices which aspiring us
                            to bring innovation.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="cards_item">
                      <div className="my-card justify-content-center align-items-center">
                        <div className="card_image p-4">
                          <Users size={100} color="#ef8405" />
                        </div>
                        <div className="card_content">
                          <h2 className="card_title text-center">Teamwork & Integrity</h2>
                          <p className="card_text mt-20 text-center">
                            Our belief, “TEAMWORK WITH INTEGRITY MAKES
                            DREAMWORK”
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}

export default Home;
