import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Navbar/Navbar";
import Home from "./Home/Home";
import Footer from "./Footer/Footer";
import About from "./about/About";
import Training from "./training/Training";
import Contact from "./contact/Contact";
import Service from "./service/Service";
import Loader from "./Loader/Loader";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";

function App() {
  const [loader, setloader] = useState(false);
  useEffect(() => {
    AOS.init({once: true , duration: 1500 });
    AOS.refresh();
    setloader(true)
    setTimeout(() => {
      setloader(false);
    }, 2000);
  }, []);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/training" element={<Training />} />
            <Route path="/service" element={<Service />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Home />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
