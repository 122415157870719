import ser1 from "../assets/ser-1.png";
import ser2 from "../assets/ser-2.jpg";
import ser3 from "../assets/ser-3.png";
import ser4 from "../assets/ser-4.jpeg";
import ser5 from "../assets/ser-5.png";
import ser6 from "../assets/ser-6.jpg";
import ser7 from "../assets/ser-7.jpg";
import ser8 from "../assets/ser-8.jpg";
import ser9 from "../assets/ser-9.png";

export const data = [
  {
    id: 1,
    title: "Analytical Testing",
    content:
      "<ul><li> • Analytical Testing of Pharmaceuticals</li><li> • Analytical Testing of Cosmetics products</li><li> • Test for Residual Solvent / Organic Volatile Impurities by Gas Chromatography , GC-HS as per USP, BP, EP, IP for both APIs and drug products</li><li> • API characterization and analysis</li><li> • Pharmaceutical Compendial Testing (USP, EP, BP, JP, IP)</li><li> • USP / NF Compendial Monograph Testing</li><li> • Limit Test for Impurities</li><li> • Content uniformity testing</li><li> • Assays for Drug Substances and Formulations</li><li> • System Suitability Testing</li><li> • Pharmaceutical Assays</li><li> • Test for Particulate matter (USP, EP) for: Small volume parenterals (SVPs), Large volume parenterals (LVPs), Powder for injection, Ophthalmic preparations, etc.</li></ul>",
    img: ser1,
  },
  {
    id: 2,
    title: "Analytical Support",
    content: "<ul><li> • Analytical method development</li><li> • Bio-Analytical Method Development and Validation</li><li> • Comparative Dissolution Study</li><li> • Disintegration and Frialbility</li><li> • Impurity Isolation and Characterization</li><li> • Method Validation as per ICH and US guideline.</li><li> • Excipient compatibility Study</li><li> • Extractable and Leachable Testing of Primary and secondary packaging</li><li> • Pharmacopoeial and IH Method verification, R & D, F & D activities</li></ul>",
    img: ser2,
  },
  {
    id: 3,
    title: "Food Testing",
    content: "<ul><li> • Analysis of Food & Agricultural Products.</li><li> • Contract Testing Services to Support your HACCP, ISO, GMP andGLP Compliance</li><li> • Fatty Acids composition and trans fat content</li><li> • Proximate analysis and nutritional labeling of food products.</li><li> • Shelf life estimation of packed food</li><li> • Analysis of Alcoholic and nonalcoholic beverages.</li><li> • Cereals testing</li><li> • Edible Oil Testing</li><li> • Spices Testing</li><li> • Confectionery Testing Services</li></ul>",
    img: ser3,
  },
  {
    id: 4,
    title: "General Chemical Testing",
    content: "<ul><li> • Raw Materials and Excipients identification tests</li><li> • Fine chemicals (Organic and Inorganic)</li><li> • Insecticides and Pesticides</li><li> • Minerals and ores</li><li> • Oils and Fats</li><li> • Soaps, Hand wash liquid, Dish Wash Liquid andDetergents</li><li> • Fertilizers Products.</li><li> • Steroids Tablets</li><li> • Paint Testing Service</li><li> • Food Colour And Edible Flavour Testing Services</li></ul>",
    img: ser4,
  },
  {
    id: 5,
    title: "Microbiological",
    content: "<ul><li> • Antibiotic Assays</li><li> • Assay of Vitamins</li><li> • Bioburden Determination Testing</li><li> • Identification and Characterization of Microorganism</li><li> • Microbiological Testing of Formulation</li><li> • Microbial Limit Test (MLT)</li><li> • Pathogen Identification Sterility Testing</li><li> • Testing of Bacterial Endotoxin (LAL Test)</li><li> • Validation of Microbial Test Methods</li></ul>",
    img: ser5,
  },
  {
    id: 6,
    title: "Phytochemical",
    content: "<ul><li> • Development of Herbal Formulations</li><li> • Determination of Heavy Metals and Microbial Contamination</li><li> • Extraction, Fractionation and Otpimization</li><li> • Estimation of Phytochemical</li><li> • Isolation & Characterization</li><li> • Standardization of Herbal and Ayurvedic Medicines</li><li> • Validation of Ayurvedic Medicine (Siddha &Unani products).</li></ul>",
    img: ser6,
  },
  {
    id: 7,
    title: "Stability Testing",
    content: " <ul><li> • All Zones Stability Testing are available</li><li> • Determination of Shelf life of Medical Device & Disposables</li><li> • Determination of Shelf life of Medical Device & Disposables</li><li> • Real Time Stability Studies and Testing</li><li> • Accelerated Aging Stability Studies and Testing</li><li> • Stability Testing as per ICH Guideline</li></ul>",
    img: ser7,
  },
  {
    id: 8,
    title: "Testing for Physical and Chemical Properties",
    content: "<ul><li> • Testing of pH</li><li> • Conductivity</li><li> • Moisture</li><li> • Osmolarity</li><li> • Test for Crystallinity by optical microscopy</li><li> • Melting Range Testing</li><li> • Pharmaceutical Hardesns Testing</li><li> • Flashpoint</li><li> • Viscosity</li><li> • Specific Gravity</li><li> • Potentiometric titrations</li><li> • Particle size analysis</li><li> • Specific Rotation Testing</li></ul>",
    img: ser8,
  },
  {
    id: 9,
    title: "Water Analysis",
    content: "<ul><li> • Potable Water</li><li> • Swimming pool water</li><li> • Ground Water Chemical Analysis</li><li> • Mineral Water Analysis</li><li> • Boiler feed water</li><li> • Industrial Effluents</li><li> • Packaged Drinking Water</li><li> • Reagent Grade Water</li></ul>",
    img: ser9,
  },
];


