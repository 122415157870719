import React from 'react'
import "./loader.css"
function Loader() {
  return (
    <div class="box">
    <div class="capsule-body">
      <div class="capsule-upper">
      <div class="upper-highlight"></div>
    </div>
      <div class="lower-highlight"></div>
      <div class="base-shadow"></div>
    </div>
    <div id="txt">
      
   

    </div>

  </div>
  )
}

export default Loader