import React from 'react'

function Banner({title}) {
  return (
    <>
      <section className="breadcrumb__area pt-100 pb-120 breadcrumb__overlay">
          <div className="container">
           <center>
                  <h2 data-aos="fade" className="tp-breadcrumb__title banner-title">{title}</h2>
            </center>
          </div>
        </section>
    </>
  )
}

export default Banner