import React from "react";
import Banner from "../common-layouts/Banner";
import "./train.css";
import ProgramCard from "./ProgramCard";
import {
  BadgeCheck,
  FlaskConical,
  Lightbulb,
  Microscope,
  TestTubes,
  UserCheck,
} from "lucide-react";
function Training() {
  return (
    <>
      <Banner title="Training" />
      <section className="services-area pt-40 pb-10 grey-bg">
        <div className="container">
          <div
            className="d-flex flex-column justify-content-center align-items-center train-program mb-25"
            data-aos="fade-up"
          >
            <span className="tp-section__sub-title left-line right-line mb-50">
              Training Program
            </span>
            <p className="text-center mb-50">
              At Wave Analytical Labs LLP (Formerly known as Wave Analytical &
              Research Center), to fulfil the essintial requirement of
              technically sound employment in pharmaceutical industry, we offer
              short term certified training programs. These programs are
              beneficial & recommended for all those who are working or looking
              to join departments like ADL, QC, QA in Pharmaceutical industries.
            </p>
            <p className="mb-50">
              <span style={{ fontWeight: "bolder" }}>Eligibility:</span> Fresher
              / Experienced with Science Graduates, Pharmacy students, Research
              scholars, Chemical Engineers. (Course study material is provided
              by us.)
            </p>
          </div>
          <center>
            <span
              className="tp-section__sub-title left-line right-line mb-50"
              data-aos="fade-up"
            >
              Available Programs
            </span>
          </center>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4">
              <ProgramCard
                icon={<FlaskConical size={37} />}
                content=" Advance Practical Theoretical study with Hands on training
              of HPLC, GC, FTIR, UV etc. lab equipments"
              />
            </div>
            <div className="col-lg-4">
              <ProgramCard
                icon={<UserCheck size={37} />}
                content="Training for Analytical method development and validation"
              />
            </div>
            <div className="col-lg-4">
              <ProgramCard
                icon={<TestTubes size={37} />}
                content="Advance Chromatographic separation study"
              />
            </div>
          </div>
          <div className="row" data-aos="fade-up">
            <div className="col-lg-4">
              <ProgramCard
                icon={<Microscope size={37} />}
                content="Hands on training program on Microbiology and chemical lab practices"
              />
            </div>
            <div className="col-lg-4">
              <ProgramCard
                icon={<Lightbulb size={37} />}
                content="Advanced training on Patent and literature Search"
              />
            </div>
            <div className="col-lg-4">
              <ProgramCard
                icon={<BadgeCheck size={37} />}
                content=" Advance training about Quality control and Quality assurance"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Training;
