import { Link, useLocation, useNavigate } from "react-router-dom";
import logos from "../assets/newLogo.jpeg";
import smallogo from "../assets/smalllogo.png";
import { useEffect, useState } from "react";
import "./navbar.css";
import { Mail, Menu, PhoneCall, X } from "lucide-react";
function Navbar() {
  const [toggler, setToggler] = useState(false);
  const location = useLocation();

  // screen resize for changing logo
  const [imageSrc, setImageSrc] = useState(false);
  const handleWindowResize = () => {
    if (window.innerWidth <= 480) {
      setImageSrc(true);
    } else {
      setImageSrc(false);
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // for drawer
  useEffect(() => {
    if (toggler) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "auto");
  }, [toggler]);

  function handleNavClick() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    setToggler(false);
  }

  const navigatehome = useNavigate();
  function homeNav() {
    navigatehome("/");
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }
  return (
    <>
    {toggler && <div className="overlay"></div>}
      <nav className={toggler ? "hide-nav" : null}>
        {toggler ? null : imageSrc ? (
          <img
            src={smallogo}
            alt="small-logo"
            id="small-logo"
            onClick={homeNav}
          />
        ) : (
          <img src={logos} alt="logo" id="big-logo" onClick={homeNav} />
        )}
        <div className="abc">
          {
            toggler ? null : <div className="mobile-things">
            <a href="tel:+91 8140825509">
              <PhoneCall color="#007fb4"/>
            </a>
            <a href="mailto:waveanalytical.com">
              <Mail color="#007fb4" />
            </a>
          </div>
          }
        
          <ul
            id="wave-navbar"
            className={toggler ? "#wave-navbar active" : "#wave-navbar"}
          >
            <li className="mobile-side-logo">
              <img src={logos} alt="logo" />
              <X size={40} onClick={() => setToggler(false)}/>
            </li>
            <li>
              <Link
                to="/"
                className={`${location.pathname === "/" ? "activate" : ""}`}
                onClick={() => handleNavClick()}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`${
                  location.pathname === "/about" ? "activate" : ""
                }`}
                onClick={() => handleNavClick()}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/service"
                className={`${
                  location.pathname === "/service" ? "activate" : ""
                }`}
                onClick={() => handleNavClick()}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/training"
                className={`${
                  location.pathname === "/training" ? "activate" : ""
                }`}
                onClick={() => handleNavClick()}
              >
                Training
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`${
                  location.pathname === "/contact" ? "activate" : ""
                }`}
                onClick={() => handleNavClick()}
              >
                Contact
              </Link>
            </li>

            <div className="mobile-call-email">
              <li>
                <a href="tel:+91 8140825509">
                  <PhoneCall />
                </a>
              </li>
              <li>
                <a href="mailto:waveanalyticallabs@gmail.com">
                  <Mail />
                </a>
              </li>
            </div>
            <li className="mobile-maps">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.4918335697503!2d72.65645697619878!3d23.115691112845006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e819b21b28943%3A0x3f48d18a54b93ebc!2sWave%20Analytical%20%26%20Research%20Center!5e0!3m2!1sen!2sin!4v1690458726302!5m2!1sen!2sin"
                className="iframe-maps"
                allowfullscreen=""
                loading="lazy"
                title="maps"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </li>
          </ul>
          {
            toggler ? null : <div id="mobile" onClick={() => setToggler(!toggler)}>
            <Menu  />
         </div>
          }
          
        </div>

        {/* mobile logos */}

      
      </nav>
    </>
  );
}

export default Navbar;
